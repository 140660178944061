import { OptionsObject } from 'notistack';
import { Column, ColumnGroup } from 'react-data-grid';
import { DatePickerSlotsComponentsProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

export const IS_DEVELOPMENT: any = process.env.NODE_ENV === 'development';

// apis urls
export const BASE_URL: string = IS_DEVELOPMENT
  ? 'http://127.0.0.1:8000/api/v1'
  : '/api/v1';
export const WEB_SOCKET_BASE_URL: string = IS_DEVELOPMENT
  ? 'ws://127.0.0.1:8000'
  : `wss://${window.location.host}`;
export const URL_EMAIL_PASSWORD_LOGIN = `${BASE_URL}/accounts/auth/login/`;
export const URL_REFRESH_TOKENS = `${BASE_URL}/auth/token/refresh/`;
export const URL_LOGOUT = `${BASE_URL}/accounts/auth/logout/`;
export const URL_USERS = `${BASE_URL}/users/`;
export const URL_PROJECTS = `${BASE_URL}/projects/`;
export const URL_PROJECTS_BULK_UPDATE = `${URL_PROJECTS}bulk-update/`;
export const URL_PROJECT_SEARCH = `${URL_PROJECTS}project-property-search/`;
export const URL_PROJECT_SUMMARIES = `${URL_PROJECTS}summary-data/`;
export const URL_PROJECT_TABLE_VIEWS = `${BASE_URL}/project-table-views/`;
export const URL_PROJECT_IMPORT_CSV = `${URL_PROJECTS}import-csv/`;
export const URL_CUSTOMERS = `${BASE_URL}/customers/`;
export const URL_CUSTOMER_SUMMARIES = `${URL_CUSTOMERS}summary-data/`;
export const URL_CUSTOMER_SEARCH = `${URL_CUSTOMERS}customer-property-search/`;
export const URL_SITES = `${BASE_URL}/sites/`;
export const URL_SITE_SEARCH = `${URL_SITES}site-property-search/`;
export const URL_SITE_IMPORT_CONTACTS = `${URL_SITES}import-contacts/`;
export const URL_TASKS = `${BASE_URL}/tasks/`;
export const URL_TASKS_SEARCH = `${URL_TASKS}task-property-search/`;
export const URL_TASKS_BULK_UPDATE = `${URL_TASKS}bulk-update/`;
export const URL_TASKS_ADD_TO_WATCH_LIST = `${URL_TASKS}add-to-watch-list/`;
export const URL_TASKS_MY_WATCH_LIST = `${URL_TASKS}my-watch-list/`;
export const URL_TASKS_READ_WATCH_LIST_ALERT = `${URL_TASKS}read-watch-list-alert/`;
export const URL_TASKS_DELETE_WATCH_LIST = `${URL_TASKS}delete-from-watch-list/`;
export const URL_TASKS_SUMMARY = `${BASE_URL}/tasks/summary/`;
export const URL_TASKS_SUMMARY_STATS = `${BASE_URL}/tasks/summary-stats/`;
export const URL_TASK_TEMPLATES = `${BASE_URL}/task-templates/`;
export const URL_SETUPS = `${BASE_URL}/setup/`;
export const URL_SETUPS_COMPANIES = `${URL_SETUPS}companies/`;
export const URL_SETUPS_COMPANY_TYPES = `${URL_SETUPS}company-types/`;
export const URL_SETUPS_PROJECT_STATUS = `${URL_SETUPS}project-statuses/`;
export const URL_SETUPS_PROJECT_SCOPE = `${URL_SETUPS}project-scopes/`;
export const URL_SETUPS_PROJECT_STAGE = `${URL_SETUPS}project-stages/`;
export const URL_SETUPS_TASKS_TATUS = `${URL_SETUPS}task-statuses/`;
export const URL_EMAIL_RECIPIENTS = `${URL_SETUPS}email-recipients/`;
export const URL_CONTACTS = `${URL_SETUPS}contacts/`;
export const URL_APP_SEARCH = `${BASE_URL}/app-search/`;
export const URL_COMMENTS = `${BASE_URL}/comments/`;
export const URL_REPORTS = `${BASE_URL}/reports/`;
export const URL_FORMS = `${BASE_URL}/forms/`;
export const URL_KANBAN_BOARDS = `${BASE_URL}/kanban-boards/`;
export const URL_KANBAN_COLUMNS = `${BASE_URL}/kanban-columns/`;
export const URL_KANBAN_CARDS = `${BASE_URL}/kanban-cards/`;
export const URL_USER_MENTIONS = `${BASE_URL}/comments/user-mentions`;
export const SOCKET_USER_ONLINE_STATUS = `${WEB_SOCKET_BASE_URL}/user/online-status/`;

/*
App
Routes*/
export const ROOT_PATH = `/`;
export const APP_PATH = `/app`;
export const NOT_FOUND_PATH = `${APP_PATH}/*`;
export const NOT_ALLOWED_PATH = `${APP_PATH}/not-allowed`;
export const LOGIN_PATH = `${APP_PATH}/account/singin`;
export const PROFILE_PATH = `${APP_PATH}/profile/:id`;
export const CUSTOMERS_PATH = `${APP_PATH}/customers`;
export const PROJECTS_PATH = `${APP_PATH}/projects`;
export const HIGH_LEVEL_PATH = `${APP_PATH}/high-level`;
export const PROJECT_DETAILS_PATH = `${APP_PATH}/projects/:id`;
export const SITES_PATH = `${APP_PATH}/sites`;
export const TASKS_PATH = `${APP_PATH}/tasks`;
export const PROJECT_TASKS_PATH = `${APP_PATH}/project-tasks`;
export const SETUPS_PATH = `${APP_PATH}/setups`;
export const NOTIFICATIONS_PATH = `${APP_PATH}/notifications`;
export const REPORTS_PATH = `${APP_PATH}/reports`;
export const KANBAN_BOARDS_PATH = `${APP_PATH}/kanban-boards`;
export const TABLE_VIEWS_PATH = `${APP_PATH}/table-views`;
export const MAG_APPS_PATH = `${APP_PATH}/mag-apps`;
export const TDC_TESTS_PATH = `${APP_PATH}/tdc-tests`;

/*end app routes*/

export type appColors = {
  blue: string;
  red: string;
  dark: string;
  light: string;
  green: string;
  purple: string;
  orange: string;
  brown: string;
  grey: string;
  darkGrey: string;
};

export const useAppColors = () => {
  const appColors = JSON.parse(localStorage['appColors'] || '{}');
  return Object.keys(appColors)?.length > 0 ? appColors : defaultColors;
};

export const getBlueColor = () => {
  return useAppColors().blue;
};
export const getRedColor = () => {
  return useAppColors().red;
};
export const getDarkColor = () => {
  return useAppColors().dark;
};
export const getLightColor = () => {
  return useAppColors().light;
};
export const getGreenColor = () => {
  return useAppColors().green;
};
export const getPurpleColor = () => {
  return useAppColors().purple;
};
export const getOrangeColor = () => {
  return useAppColors().orange;
};
export const getBrownColor = () => {
  return useAppColors().brown;
};
export const getGreyColor = () => {
  return useAppColors().grey;
};
export const getDarkGreyColor = () => {
  return useAppColors().darkGrey;
};

// Default colors (fallback values)
export const defaultColors: appColors = {
  blue: '#006485',
  red: '#E1003C',
  dark: '#2C2F30',
  light: '#E0E0DB',
  green: '#00D28F',
  purple: '#7E1DDB',
  orange: '#DB9A1D',
  brown: '#A1431B',
  grey: '#c6c5c5',
  darkGrey: '#777D7F',
};

// styles sx
export const APP_BUTTON_SX = {
  borderColor: getRedColor(),
  borderWidth: 2,
  my: 1,
  borderStyle: 'solid',
  borderRadius: 1,
  color: getLightColor(),
  bgcolor: getRedColor(),
  '&:hover': {
    bgcolor: getBlueColor(),
    color: getLightColor(),
    borderColor: getBlueColor(),
  },
  '&.Mui-disabled': {
    borderColor: getLightColor(),
  },
};

export const navButtonStyles = {
  color: getLightColor(),
  textAlign: 'justify',
  '&:hover': {
    bgcolor: getRedColor(),
    borderTop: `2px solid ${getBlueColor()}`,
    borderBottom: `2px solid ${getBlueColor()}`,
  },
  '&.Mui-selected': {
    bgcolor: getRedColor(),
    color: getLightColor(),
    '&:hover': {
      color: getLightColor(),
      bgcolor: getDarkColor(),
    },
  },
};

export const navButtonItemIconStyles = {
  color: 'inherit',
  minWidth: 0,
  justifyContent: 'center',
  flexShrink: 'inherit',
};
export const ERROR_SNACKBAR_OPTIONS: OptionsObject = {
  variant: 'error',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  preventDuplicate: true,
};
export const SUCCESS_SNACKBAR_OPTIONS: OptionsObject = {
  variant: 'success',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  preventDuplicate: true,
};

export const WARNING_SNACKBAR_OPTIONS: OptionsObject = {
  variant: 'warning',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  preventDuplicate: true,
};

// query names

export const CUSTOMER_QUERY_KEY = 'customers';
export const CUSTOMER_SUMMARY_QUERY_KEY = 'customer-summaries';
export const PROJECT_QUERY_KEY = 'projects';
export const PROJECT_DETAIL_QUERY_KEY = 'project-detail';
export const SITE_QUERY_KEY = 'sites';
export const TASK_QUERY_KEY = 'tasks';
export const TASK_DETAIL_QUERY_KEY = 'task-detail';
export const TASK_SUMMARY_QUERY_KEY = 'tasks-summary';
export const TASK_SUMMARY_STATS_QUERY_KEY = 'tasks-summary-stats';
export const TEMPLATE_QUERY_KEY = 'task-templates';
export const USERS_QUERY_KEY = 'users';
export const CONTACTS_QUERY_KEY = 'contacts';
export const ONLINE_USERS_QUERY_KEY = 'online-users';
export const COMPANY_QUERY_KEY = 'companies';
export const TASK_WATCH_LIST_QUERY_KEY = 'task-watch-list';
export const EMAIL_RECIPIENTS_QUERY_KEY = 'email-recipients';
export const COMPANY_TYPE_QUERY_KEY = 'company-types';
export const REPORT_QUERY_KEY = 'reports';
export const REPORT_DATA_QUERY_KEY = 'reports-data';
export const KANBAN_QUERY_KEY = 'kanbans';
export const TABLE_VIEW_QUERY_KEY = 'table-views';
export const PROJECT_SUMMARY_QUERY_KEY = 'project-summaries';
export const PROJECT_STATUS_QUERY_KEY = 'project-statuses';
export const PROJECT_SCOPE_QUERY_KEY = 'project-scopes';
export const PROJECT_STAGE_QUERY_KEY = 'project-stages';
export const TASK_STATUS_QUERY_KEY = 'task-statuses';
export const APP_SEARCH_QUERY_KEY = 'app-searches';
export const TASK_SEARCH_QUERY_KEY = 'task-searches';
export const SITE_SEARCH_QUERY_KEY = 'site-searches';
export const CUSTOMER_SEARCH_QUERY_KEY = 'customer-searches';
export const PROJECT_SEARCH_QUERY_KEY = 'project-searches';
export const COMMENTS_QUERY_KEY = 'comments';
export const RECURRING_REQUESTS_QUERY_KEY = 'recurring-requests';
export const USER_MENTIONS_QUERY_KEY = 'user-mentions';
export const RECENT_COMMENTS_QUERY_KEY = 'recent-comments-components';
export const REPORT_COMMENTS_QUERY_KEY = 'report-comments';
export const REPORT_FORM_LOGS_QUERY_KEY = 'report-form-logs';

// local storage keys
export const KEY_VERSION = 26;
export const COLUMN_SIZING_KEY = `_tdc_columns_sizing_v${KEY_VERSION}`;
export const COLUMN_HIDING_KEY = `_tdc_columns_hiding_v${KEY_VERSION}`;
export const COLUMN_FROZEN_KEY = `_tdc_columns_frozen_v${KEY_VERSION}`;
export const COLUMN_EXPANDED_KEY = `_tdc_columns_expanded_v${KEY_VERSION}`;
export const HEAD_GROUPED_EXPANDED_KEY = `_tdc_head_group_expanded_v${KEY_VERSION}`;
export const COLUMN_ORDER_KEY = `_tdc_columns_order_v${KEY_VERSION}`;
export const TABLE_SORT_VALUES = `_tdc_table_sort_values_v${KEY_VERSION}`;
export const TABLE_FILTERED_PROPERTIES = `_tdc_table_filtered_properties_v${KEY_VERSION}`;
export const TABLE_FIRST_SELECTED_PROPERTY = `_tdc_table_first_selected_property_v${KEY_VERSION}`;
export const TABLE_HORIZONTAL_SCROLL_POS = `_tdc_table_horizontal_scroll_pos_v${KEY_VERSION}`;
export const KANBAN_COLUMN_COLLAPSE_KEY = `_kanban_column_collapse_v${KEY_VERSION}`;
export const KANBAN_CARD_COLLAPSE_KEY = `_kanban_card_collapse_v${KEY_VERSION}`;
export const PROJECT_TASK_ID = `_project_task_id_v${KEY_VERSION}`;

//comment email actions
export const LEAD_EMAIL_ACTION = 'lead';
export const TEAM_EMAIL_ACTION = 'team';
export const DEV_EMAIL_ACTION = 'dev';
export const SALES_EMAIL_ACTION = 'sales';
export const REQUEST_EMAIL_ACTION = 'request';
export const REQUEST_BY_EMAIL_ACTION = 'email';
export const ADD_TO_WATCH_LIST = 'watch';

// Define a custom type that extends Column
export type CustomColumn<R, SR = unknown> = Column<R, SR> & {
  omit?: boolean;
  hidden?: boolean;
  showFilters?: boolean;
  hoverHintText?: string;
  disableSelection?: boolean;
  meta?: {
    editable: boolean;
    inputType?: string;
    maxLength?: number;
  };
};

export type CustomColumnGroup<R, SR = unknown> = ColumnGroup<R, SR> & {
  readonly children: readonly CustomColumnOrColumnGroup<R, SR>[];
};

export type CustomColumnOrColumnGroup<R, SR = unknown> =
  | CustomColumn<R, SR>
  | CustomColumnGroup<R, SR>;

export const TABLE_PAGE_SIZE = 100;
export const QUERY_REFETCH_INTERVAL = 5 * 60 * 1000;

export const PROJECT_GROUPED_COLUMNS: any = {
  'contact-data': [
    'customer_lead.full_name',
    'mag_development_lead.initials',
    'mag_sales_lead.initials',
    'broker.name',
    'channel_partner.name',
    'channel_partner_primary_contact.full_name',
    'channel_partner_bdm.full_name',
    'broker_contact.full_name',
  ],
  'geographic-data': [
    'site.city',
    'site.state.state_code',
    'site.country.iso3',
  ],
  'project-dates': [
    'survey_latest_complete_date',
    'installation_complete_date',
    'installation_start_date',
    'date_proposed',
    'date_contract',
  ],
  'stage-board': [
    'working_stage',
    'stages.CLST.status',
    'stages.INST.status',
    'stages.PRIN.status',
    'stages.CNTR.status',
    'stages.PRPL.status',
    'stages.IGAD.status',
    'stages.ESTM.status',
    'stages.DVMNT.status',
    'stages.SRVY.status',
    'stages.PRDV.status',
    'stages.PRSP.status',
    'stages.PRPR.status',
  ],
  'proposed-financial-data': [
    'probability_of_close_proposed',
    'mag_project_value_proposed',
    'incentive_amount_proposed',
    'mag_contract_value_proposed',
    'funded_value_proposed',
  ],
  'proposed-energy-data': [
    'kw_savings_proposed',
    'kwh_savings_proposed',
    'mmbtu_savings_proposed',
    'total_cost_savings_proposed',
    'carbon_savings_proposed',
  ],
  'proposed-solar-system': [
    'mounting_type',
    'estimated_dc_capacity',
    'estimated_ac_capacity',
  ],
  'contract-financial-data': [
    'mag_project_value_contract',
    'incentive_amount_contract',
    'mag_contract_value_contract',
    'funded_value_contract',
  ],
  'contract-energy-data': [
    'kw_savings_contract',
    'kwh_savings_contract',
    'mmbtu_savings_contract',
    'total_cost_savings_contract',
    'carbon_savings_contract',
  ],
  'financial-performance': [
    'billing_actual',
    'total_costs_contract',
    'total_costs_actual',
    'margin_contract',
    'margin_actual',
    'profit_loss_actual',
  ],
};

export const datePickerSlotProps: DatePickerSlotsComponentsProps<Dayjs> = {
  textField: {
    variant: 'outlined',
    sx: { padding: 0, width: '100%' },
    inputProps: {
      sx: { padding: '10.5px 0 10.5px 0', paddingY: 0 },
      // onBlur: onBlur,
    },
  },
  field: {
    clearable: true,
  },
  clearButton: {
    sx: { padding: 0, height: 'auto', width: 'auto', marginLeft: 0 },
  },
  clearIcon: {
    sx: { color: 'inherit', fontSize: 'inherit', marginLeft: 0 },
  },
  inputAdornment: {
    sx: { marginLeft: 0 },
  },
  openPickerButton: {
    sx: { padding: 0 },
  },
};

export const TAB_SX = {
  color: getLightColor(),
  '&.Mui-selected': { color: getLightColor() },
};

export const TAB_PANEL_SX = {
  background: getLightColor(),
};

export const quillFontSizes = [
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '32px',
  '48px',
];

export const TDC_DATA_NAMES = {
  recentComment: 'RecentComment',
  taskWatchList: 'TaskWatchList',
  taskSummary: 'TaskSummary',
  kanbanBuild: 'KanbanBoardBuild',
  projectRecentComment: 'ProjectRecentComment',
  recurringRequest: 'RecurringRequest',
  builtInReport: 'BuiltInReport',
  customReport: 'CustomReport',
  projectComment: 'ProjectComment',
  reportBuild: 'ReportBuild',
  quickCalculator: 'LightningQuickCalculator',
  company: 'Company',
  contact: 'Contact',
  emailRecipient: 'EmailRecipient',
  projectScope: 'ProjectScope',
  projectStage: 'ProjectStage',
  projectStatus: 'ProjectStatus',
  taskStatus: 'TaskStatus',
  taskTemplate: 'TaskTemplate',
  user: 'User',
  customer: 'Customer',
  kanbanBoard: 'Kanban-Board',
  notification: 'Notification',
  project: 'Project',
  task: 'Task',
  groupedTask: 'GroupedTask',
  report: 'Report',
  site: 'Site',
  tableView: 'Table-View',
};
