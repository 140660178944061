import { createContext, useContext, useMemo, useState } from 'react';
import { AuthActions } from '../network/AuthActions';
import { User } from '../network/models/AccountModels';
import { appColors, defaultColors } from './Constants';

interface AppContextInterface {
  user: User;
  userNotifications: {
    mentions: any[];
    watch_list_alerts: any[];
  };
  appColors: appColors;
}

const AppStateContext = createContext<any>({});

const AppContextProvider = (props: any) => {
  const { children } = props;
  const appColors = JSON.parse(localStorage['appColors'] || '{}');
  const accountActions: any = new AuthActions();
  const [globalContentState, setGlobalContentState] =
    useState<AppContextInterface>({
      user: accountActions.retrieveAuthenticatedUser(),
      userNotifications: { mentions: [], watch_list_alerts: [] },
      appColors: Object.keys(appColors)?.length > 0 ? appColors : defaultColors,
    });
  const value = useMemo(
    () => ({ globalContentState, setGlobalContentState }),
    [globalContentState]
  );
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export { AppContextProvider, AppStateContext };
