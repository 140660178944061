// ==============================|| OVERRIDES - LINK ||============================== //

import { getBlueColor } from '../../utils/Constants';

export default function Link() {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          '&.MuiLink-underlineAlways': {
            textDecoration: 'underline',
            color: getBlueColor(),
            '&:hover': {
              color: getBlueColor(),
            },
          },
        },
      },
      defaultProps: {
        underline: 'always',
      },
    },
  };
}
