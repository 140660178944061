// ==============================|| OVERRIDES - TextField ||============================== //

import { getDarkColor } from '../../utils/Constants';

export default function TextField(theme: any) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: getDarkColor(),
        },
        outlined: {
          '&.MuiInputLabel-shrink': {
            color: getDarkColor(),
          },
        },
        standard: {
          '&.MuiInputLabel-shrink': {
            color: getDarkColor(),
          },
        },
      },
    },
    MuiInput: {
      // Use MuiInput here
      styleOverrides: {
        underline: {
          // Use underline class to target the border-bottom
          '&:after': {
            borderBottomColor: getDarkColor(), // Change the border bottom color here
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottomColor: getDarkColor(), // Change the border bottom color on hover
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: getDarkColor(),
          // backgroundColor: COLOR_LIGHT,
          '&.Mui-focused': {
            color: getDarkColor(),
          },
        },
      },
    },
  };
}
