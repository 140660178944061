import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import PeopleIcon from '@mui/icons-material/People';
import TaskIcon from '@mui/icons-material/Task';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Divider, IconButton, styled } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  APP_PATH,
  getBlueColor,
  getOrangeColor,
  CUSTOMERS_PATH,
  KANBAN_BOARDS_PATH,
  navButtonItemIconStyles,
  navButtonStyles,
  PROJECT_TASKS_PATH,
  PROJECTS_PATH,
  REPORTS_PATH,
  ROOT_PATH,
  SITES_PATH,
  TABLE_VIEWS_PATH,
  TASKS_PATH,
  MAG_APPS_PATH,
} from '../../utils/Constants';
import {
  AnalyticsRounded,
  ListAltRounded,
  PreviewRounded,
  ViewColumnRounded,
  AppsRounded,
  HandymanRounded,
} from '@mui/icons-material';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function SidebarDrawer(props: any) {
  const { isDrawerOpen, handleDrawerClose, CustomDrawer } = props;
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState<string>('');

  const handleMenuClick = (menu: string) => {
    setOpenMenu(menu === openMenu ? '' : menu);
  };

  useEffect(() => {
    if (window.location.pathname !== REPORTS_PATH && openMenu === 'reports') {
      setOpenMenu('');
    }
    handleDrawerClose();
  }, [window.location.pathname]);

  const navigateToPath = useCallback((path: string) => {
    navigate(path);
  }, []);

  return (
    <CustomDrawer
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor:
            process.env.REACT_APP_ENV === 'staging'
              ? getOrangeColor()
              : getBlueColor(),
          color: '#ffffff',
        },
      }}
      variant="permanent"
      open={isDrawerOpen}
    >
      <DrawerHeader>
        <IconButton sx={{ color: '#ffffff' }} onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(APP_PATH)}
            selected={
              window.location.pathname === ROOT_PATH ||
              window.location.pathname === APP_PATH
            }
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <DashboardIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Dashboard'} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(CUSTOMERS_PATH)}
            selected={window.location.pathname === CUSTOMERS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <PeopleIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Customers'} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(SITES_PATH)}
            selected={window.location.pathname === SITES_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <WarehouseIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Sites'} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(PROJECTS_PATH)}
            selected={window.location.pathname === PROJECTS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <FolderIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Projects'} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(TASKS_PATH)}
            selected={window.location.pathname === TASKS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <TaskIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Tasks'} />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(PROJECT_TASKS_PATH)}
            selected={window.location.pathname === PROJECT_TASKS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <ListAltRounded />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Project Tasks'} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(REPORTS_PATH)}
            selected={window.location.pathname === REPORTS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <AnalyticsRounded />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Reports'} />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(KANBAN_BOARDS_PATH)}
            selected={window.location.pathname === KANBAN_BOARDS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <ViewColumnRounded />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Kanban Boards'} />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(TABLE_VIEWS_PATH)}
            selected={window.location.pathname === TABLE_VIEWS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <PreviewRounded />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Table Views'} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigateToPath(MAG_APPS_PATH)}
            selected={window.location.pathname === MAG_APPS_PATH}
            sx={navButtonStyles}
          >
            <ListItemIcon
              sx={{
                ...navButtonItemIconStyles,
                display: isDrawerOpen ? 'inline-flex' : 'block',
              }}
            >
              <HandymanRounded />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary={'Mag Apps'} />}
          </ListItemButton>
        </ListItem>
      </List>
    </CustomDrawer>
  );
}

export default SidebarDrawer;
