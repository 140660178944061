import jwt_decode from 'jwt-decode';
import { User } from './models/AccountModels';
import {
  COLUMN_EXPANDED_KEY,
  COLUMN_FROZEN_KEY,
  COLUMN_HIDING_KEY,
  COLUMN_ORDER_KEY,
  COLUMN_SIZING_KEY,
  KEY_VERSION,
  TABLE_FILTERED_PROPERTIES,
  TABLE_FIRST_SELECTED_PROPERTY,
  TABLE_HORIZONTAL_SCROLL_POS,
  TABLE_SORT_VALUES,
  TDC_DATA_NAMES,
} from '../utils/Constants';
import { UserAPIs } from './apis/UserAPIs';
import { NetworkRequests } from './NetWorkRequests';
import TableUtils from '../components/table-components/TableUtils';
import Cookies from 'js-cookie';

export class AuthActions {
  private ACCESS_COOKIE = 'access_token';
  private REFRESH_COOKIE = 'refresh_token';
  private isLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';
  private cookieOptions = {
    secure: !this.isLocalhost, // ✅ Secure cookies only in production
    sameSite: this.isLocalhost ? 'Lax' : 'None', // ✅ "Lax" for localhost, "None" for cross-domain
    domain: this.isLocalhost ? undefined : '.magenergy.com', // ✅ No domain for localhost
    path: '/', // ✅ Ensure it's available site-wide
  } as Cookies.CookieAttributes;

  storeAuthenticationTokens(tokens: {
    access_token: string;
    refresh_token: string;
  }) {
    if (![undefined, ''].includes(tokens?.access_token)) {
      // ✅ Always update cookies, replacing old values
      Cookies.remove(this.ACCESS_COOKIE, this.cookieOptions);
      Cookies.remove(this.REFRESH_COOKIE, this.cookieOptions);

      Cookies.set(this.ACCESS_COOKIE, tokens.access_token, this.cookieOptions);
      Cookies.set(
        this.REFRESH_COOKIE,
        tokens.refresh_token,
        this.cookieOptions
      );
    }
  }

  retrieveAuthenticationTokens() {
    return {
      access_token: Cookies.get(this.ACCESS_COOKIE) || '_',
      refresh_token: Cookies.get(this.REFRESH_COOKIE) || '_',
    };
  }

  retrieveAuthenticatedUser() {
    const tokens = this.retrieveAuthenticationTokens();
    const { access_token } = tokens;
    try {
      const decoded: any = jwt_decode(access_token);
      return new User(decoded);
    } catch (e) {
      return null;
    }
  }

  isAuthenticated() {
    const tokens = this.retrieveAuthenticationTokens();
    const { access_token, refresh_token } = tokens;

    try {
      const accessDecode: any = jwt_decode(access_token);
      const refreshDecode: any = jwt_decode(refresh_token);

      if (!access_token || !refresh_token) {
        return false;
      }

      const accessIsExpired = new Date().getTime() > accessDecode.exp * 1000;
      const refreshIsExpired = new Date().getTime() > refreshDecode.exp * 1000;

      if (accessIsExpired && refreshIsExpired) {
        // Both tokens expired → User is not authenticated
        return false;
      } else if (accessIsExpired && !refreshIsExpired) {
        // Access token expired but refresh token is valid → Refresh access token
        const ntr = new NetworkRequests();
        ntr.refreshTokenRequest(() => null);
        return true; // Temporarily return true while refreshing
      } else {
        // Both tokens are valid → User is authenticated
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  logout() {
    Cookies.remove(this.ACCESS_COOKIE, this.cookieOptions);
    Cookies.remove(this.REFRESH_COOKIE, this.cookieOptions);
    if (this.isLocalhost) {
      Cookies.remove(this.ACCESS_COOKIE, { path: '/', domain: '127.0.0.1' });
      Cookies.remove(this.REFRESH_COOKIE, { path: '/', domain: '127.0.0.1' });
    }
  }

  static saveUserTableSettings = (dataName: string, userAPIs: UserAPIs) => {
    const authActions = new AuthActions();
    const user = authActions.retrieveAuthenticatedUser();
    if (user) {
      let tableSettings = {};
      Object.values(TDC_DATA_NAMES)?.forEach((dName: string) => {
        tableSettings = {
          ...tableSettings,
          ...TableUtils.getTableSettings(dName),
        };
      });
      // const tableSettings = TableUtils.getTableSettings(dataName);
      userAPIs.updatesUserProfile(
        {
          table_settings: {
            ...user.table_settings,
            ...tableSettings,
          },
        },
        { id: user?.id, isTokens: true },
        (is_successful: boolean, user_or_error: any) => {
          if (is_successful) {
            if (user_or_error?.tokens)
              authActions.storeAuthenticationTokens(user_or_error?.tokens);
            if (user_or_error?.user) {
              const user = user_or_error?.user;
              Object.entries(user?.table_settings ?? {})?.forEach(
                ([key, value]: [string, any]) => {
                  localStorage[key] = value;
                }
              );
            }
            // To be removed later
            authActions.clearTableCache(dataName);
          } else {
            console.log(user_or_error);
          }
        }
      );
    }
  };

  static saveUserKanbanSettings = (kanbanName: string, userAPIs: UserAPIs) => {
    const authActions = new AuthActions();
    const user = authActions.retrieveAuthenticatedUser();
    if (user) {
      const kanbanSettings = TableUtils.getKanbanSettings(kanbanName);

      userAPIs.updatesUserProfile(
        {
          kanban_settings: {
            ...user.kanban_settings,
            ...kanbanSettings,
          },
        },
        { id: user?.id },
        (is_successful: boolean, user_or_error: any) => {
          if (is_successful) {
            if (user_or_error?.tokens) {
              authActions.storeAuthenticationTokens(user_or_error?.tokens);
            }
            if (user_or_error?.kanban_settings) {
              Object.entries(user_or_error?.kanban_settings ?? {})?.forEach(
                ([key, value]: [string, any]) => {
                  localStorage[key] = value;
                }
              );
            }
          } else {
            console.log(user_or_error);
          }
        }
      );
    }
  };

  clearTableCache(dataName: string) {
    localStorage.removeItem(
      `${dataName}${COLUMN_HIDING_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_SIZING_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_EXPANDED_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_ORDER_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_FROZEN_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    Array.from(Array(KEY_VERSION - 1).keys()).forEach((version) => {
      localStorage.removeItem(
        `${dataName}${COLUMN_HIDING_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}_tdc_columns_visibility_v${KEY_VERSION}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${COLUMN_SIZING_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${COLUMN_EXPANDED_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${COLUMN_ORDER_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${COLUMN_FROZEN_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${TABLE_FIRST_SELECTED_PROPERTY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${TABLE_FILTERED_PROPERTIES}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${TABLE_HORIZONTAL_SCROLL_POS}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${TABLE_SORT_VALUES}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
    });
  }
}
