import {
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  Link,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import {
  APP_BUTTON_SX,
  getDarkColor,
  getLightColor,
  getOrangeColor,
  getRedColor,
  ERROR_SNACKBAR_OPTIONS,
  NOTIFICATIONS_PATH,
  PROJECTS_PATH,
  SUCCESS_SNACKBAR_OPTIONS,
  TASKS_PATH,
} from '../../../utils/Constants';
import * as React from 'react';
import { useContext, useMemo } from 'react';
import HelperFunctions from '../../../utils/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { TaskWatchList } from '../../../network/models/AppModels';
import { useSnackbar } from 'notistack';
import { CommentAPIs } from '../../../network/apis/CommentAPIs';
import { TaskAPIs } from '../../../network/apis/TaskAPIs';
import CloseIcon from '@mui/icons-material/Close';
import { AppStateContext } from '../../../utils/AppStateContext';

interface NotificationPopupProps {
  anchorEl: any;
  open: boolean;
  setOpen: (val: boolean) => void;
  notifications: {
    mentions: any[];
    watch_list_alerts: any[];
  };
}

function NotificationPopup(props: NotificationPopupProps) {
  const { anchorEl, open, setOpen, notifications } = props;
  const navigate = useNavigate();
  const { globalContentState, setGlobalContentState } =
    useContext(AppStateContext);
  const { enqueueSnackbar } = useSnackbar();
  const commentApis = useMemo(() => new CommentAPIs(), []);
  const tasksApis = useMemo(() => new TaskAPIs(), []);

  const markCommentRead = (pks: string[]) => {
    commentApis.markReadCommentById(
      pks,
      (is_successful: boolean, model_or_error: any) => {
        if (is_successful) {
          setGlobalContentState({
            ...globalContentState,
            userNotifications: {
              ...globalContentState?.userNotifications,
              mentions: notifications?.mentions?.filter(
                (mention: any) => !pks?.includes(mention?.comment?.id)
              ),
            },
          });
          enqueueSnackbar(`Marked as read.`, SUCCESS_SNACKBAR_OPTIONS);
        } else {
          enqueueSnackbar(model_or_error, ERROR_SNACKBAR_OPTIONS);
        }
      }
    );
  };

  const readWatchListAlert = (pks: string[]) => {
    tasksApis.readWatchListAlert(
      { pks },
      (is_successful: boolean, model_or_error: any) => {
        if (is_successful) {
          setGlobalContentState({
            ...globalContentState,
            userNotifications: {
              ...globalContentState?.userNotifications,
              watch_list_alerts: notifications?.watch_list_alerts?.filter(
                (alert: TaskWatchList) => !pks?.includes(alert?.id)
              ),
            },
          });
          enqueueSnackbar(`Marked as read.`, SUCCESS_SNACKBAR_OPTIONS);
        } else {
          enqueueSnackbar(model_or_error, ERROR_SNACKBAR_OPTIONS);
        }
      }
    );
  };

  const clearALLNotifications = () => {
    const commentIds: string[] = notifications?.mentions?.map(
      (mention: any) => mention?.comment?.id ?? ''
    );
    const watchListIds: string[] = notifications?.watch_list_alerts?.map(
      (watch_list: TaskWatchList) => watch_list?.id ?? ''
    );
    markCommentRead(commentIds);
    readWatchListAlert(watchListIds);
  };

  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement={'bottom-end'}
      sx={{
        zIndex: 10000,
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;',
      }}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box>
          <Stack
            spacing={2}
            sx={{
              p: 1,
              width: '300px',
              maxHeight: '450px',
              overflow: 'auto',
              bgcolor: getDarkColor(),
            }}
          >
            {notifications?.mentions?.map((mention: any, index: number) => (
              <Stack
                key={`${mention?.comment?.id}-${index}`}
                sx={{
                  backgroundColor: getLightColor(),
                  borderRadius: 1,
                  paddingX: 1,
                }}
              >
                <Typography>
                  {mention?.comment?.user?.full_name ?? ''} mentioned you on{' '}
                  {getMentionLink(mention)}
                </Typography>
                <Divider sx={{ opacity: 0.6 }} />
                <Stack
                  spacing={2}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#979288',
                      paddingX: 1,
                      fontSize: '10px',
                    }}
                  >
                    {HelperFunctions.formatTimeStampToLocale(
                      mention?.comment.created
                    )}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      markCommentRead([mention?.comment?.id ?? '']);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
            {notifications?.watch_list_alerts?.map(
              (watch_list: TaskWatchList, index: number) => (
                <Stack
                  key={`${watch_list?.id}-${index}`}
                  sx={{
                    backgroundColor: getLightColor(),
                    borderRadius: 1,
                    paddingX: 1,
                  }}
                >
                  <Typography>
                    Task was updated: {getWatchAlertLink(watch_list)}
                  </Typography>
                  <Divider sx={{ opacity: 0.6 }} />
                  <Stack
                    spacing={2}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: '#979288',
                        paddingX: 1,
                        fontSize: '10px',
                      }}
                    >
                      {watch_list?.alert_time
                        ? HelperFunctions.formatTimeStampToLocale(
                            watch_list?.alert_time
                          )
                        : ''}
                    </Typography>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        readWatchListAlert([watch_list?.id ?? '']);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                </Stack>
              )
            )}
          </Stack>
          {(notifications?.mentions?.length > 0 ||
            notifications?.watch_list_alerts?.length > 0) && (
            <Stack direction={'row'}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ...APP_BUTTON_SX,
                  bgcolor: getOrangeColor(),
                  border: 'none',
                  borderRadius: '0',
                  // color: COLOR_DARK,
                  width: '100%',
                  my: 0,
                }}
                onClick={(e) => {
                  navigate(NOTIFICATIONS_PATH);
                  setOpen(false);
                }}
              >
                View ALL
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ...APP_BUTTON_SX,
                  bgcolor: getRedColor(),
                  border: 'none',
                  borderRadius: '0',
                  // color: COLOR_DARK,
                  width: '100%',
                  my: 0,
                }}
                onClick={(e) => {
                  clearALLNotifications();
                }}
              >
                Clear ALL
              </Button>
            </Stack>
          )}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
}

export default NotificationPopup;

export const getMentionLink = (mention: any) => {
  if (mention?.comment?.project?.id !== '' && mention?.comment?.project?.id) {
    return (
      <Link
        href={`${PROJECTS_PATH}?${HelperFunctions.objectToCommaQueryString({ p_name: mention?.comment?.project?.name || '' })}`}
      >
        {mention?.comment?.project?.name ?? ''}
      </Link>
    );
  } else if (mention?.comment?.task?.id !== '' && mention?.comment?.task?.id) {
    let paramName = mention?.comment?.task?.name ? 't_name' : 't_id';
    let paramValue = mention?.comment?.task?.name
      ? mention?.comment?.task?.name
      : mention?.comment?.task?.id
        ? mention?.comment?.task?.id
        : '';

    let project_name = mention?.comment?.task?.project_name;
    if (mention?.comment?.task?.parent_task) {
      paramValue = mention?.comment?.task?.parent_task?.name
        ? mention?.comment?.task?.parent_task?.name
        : mention?.comment?.task?.parent_task?.id
          ? mention?.comment?.task?.parent_task?.id
          : '';
      paramName = mention?.comment?.task?.parent_task?.name ? 't_name' : 't_id';
      project_name = mention?.comment?.task?.parent_task?.project_name;
    }
    return (
      <span>
        <Link
          href={`${TASKS_PATH}?${HelperFunctions.objectToCommaQueryString({
            [paramName]: paramValue,
            p_name: project_name || '',
            ...(mention?.comment?.task?.parent_task
              ? { t_expanded: mention?.comment?.task?.parent_task?.id }
              : {}),
          })}`}
        >
          Task
        </Link>
        {project_name ? ' for ' : ''}
        <Link
          href={`${PROJECTS_PATH}?${HelperFunctions.objectToCommaQueryString({ p_name: project_name || '' })}`}
        >
          {project_name ? project_name : ''}
        </Link>
      </span>
    );
  } else {
    return '';
  }
};
export const getWatchAlertLink = (watch_list: TaskWatchList) => {
  return (
    <Link
      href={`${TASKS_PATH}?${HelperFunctions.objectToCommaQueryString({
        [watch_list?.task?.parent_task
          ? 't_name'
          : watch_list?.task?.name
            ? 't_name'
            : 't_id']: watch_list?.task?.parent_task
          ? watch_list?.task?.parent_task?.name
          : watch_list?.task?.name
            ? watch_list?.task?.name
            : watch_list?.task?.id,
        p_name: watch_list?.task?.parent_task
          ? watch_list?.task?.parent_task?.project?.name
          : watch_list?.task?.project?.name || '',
        ...(watch_list?.task?.parent_task
          ? { t_expanded: watch_list?.task?.parent_task?.id }
          : {}),
      })}`}
    >
      {watch_list.task?.name || 'Blank name'}
    </Link>
  );
};
