// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

import {
  getBlueColor,
  getDarkColor,
  getLightColor,
  getRedColor,
} from '../../utils/Constants';

export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          color: getLightColor(),
          backgroundColor: getDarkColor(),
          border: `1px solid ${getLightColor()}`,
        },
        listbox: {
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: getRedColor(),
            background: getRedColor(),
            color: getLightColor(),
          },
          '& .MuiAutocomplete-groupLabel': {
            // Style for the group header
            backgroundColor: getBlueColor(),
            color: getLightColor(),
            lineHeight: '34px',
          },
        },
      },
    },
  };
}
