import React, { Component, ReactNode, useMemo } from 'react';
import { IS_DEVELOPMENT, LOGIN_PATH } from './utils/Constants';
import * as Sentry from '@sentry/react';
import { AuthActions } from './network/AuthActions';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state to trigger fallback UI on error
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log error details, you could log this to an external service
    console.error('Caught an error:', error, errorInfo);
    if (!IS_DEVELOPMENT) {
      Sentry.captureException(error, {
        extra: { componentStack: errorInfo.componentStack },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      const accountActions: any = new AuthActions();
      if (!accountActions.isAuthenticated()) {
        localStorage.clear();
        accountActions.logout();
        const path = `${LOGIN_PATH}/?next=${window.location.pathname}`;
        window.open(path, '_self');
        return null;
      }
      if (!IS_DEVELOPMENT) {
        // Reload the page if an error has occurred
        window.location.reload();
      }
      return null; // Return null while the page reloads
    }

    // Render children if there’s no error
    return this.props.children;
  }
}

export default ErrorBoundary;
