import React, { useEffect, useMemo } from 'react';
import { LOGIN_PATH } from '../utils/Constants';
import { AuthActions } from './AuthActions';
import { useNavigate } from 'react-router-dom';

function AuthenticatedUserGuard(props: any) {
  const { component } = props;
  const accountActions: any = useMemo(() => new AuthActions(), []);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!accountActions.isAuthenticated()) {
      localStorage.clear();
      accountActions.logout();
      const path = `${LOGIN_PATH}/?next=${window.location.pathname}`;
      window.open(path, '_self');
    }
  }, []);

  useEffect(() => {
    const [navigationEntry] = performance.getEntriesByType(
      'navigation'
    ) as PerformanceNavigationTiming[];

    if (
      navigationEntry &&
      navigationEntry.type === 'reload' &&
      queryParams.size > 0
    ) {
      navigate(window.location.pathname, { replace: true });
    }
  }, [navigate]);

  return <React.Fragment>{component}</React.Fragment>;
}

export default AuthenticatedUserGuard;
